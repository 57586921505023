import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      due_start: null,
      due_end: null,
      paid_start: null,
      paid_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_no', 
          label: 'เลขที่ใบกำกับภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'ref_no', 
          label: 'อ้างอิง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'debtor_code', 
          label: 'รหัสลูกหนี้', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'debtor_name', 
          label: 'ชื่อลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        },
        { 
          key: 'debtor_type', 
          label: 'ประเภท', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'due_date', 
          label: 'วันที่ครบกำหนด', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'price', 
          label: 'จำนวนเงิน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'billing', 
          label: 'รับวางบิล', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'paid', 
          label: 'จำนวนที่ชำระ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
        { 
          key: 'paid_date', 
          label: 'วันที่ชำระ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'paid_no', 
          label: 'เลขที่ชำระ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'method', 
          label: 'ชำระเงิน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right',
          sortable: true 
        },
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'PB-201665156',
          doc_date: new Date(),
          due_date: new Date(),
          inv_no: 'INV-202265165',
          ref_no: '',
          debtor_code: 'D-0001',
          debtor_name: 'ประยุทธ',
          debtor_type: 'ขายเชื่อ',
          price: 1000,
          billing: 1000,
          paid: 1000,
          paid_no: 'AR-201665156',
          paid_date: new Date(),
          method: 'เงินสด',
          nettotal: 1000
        }
      ],
      type: null,
      orderTypes: [
        { text: 'ยกมา', value: 'purchase' },
        { text: 'ขายเชื่อ', value: 'take-back' },
        { text: 'เพิ่มหนี้', value: 'import' },
        { text: 'อื่น ๆ', value: 'cancel' }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
